import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

interface Props {
  num: number
  image: string
  title: string
  description: string
  href: string | null
  wrapperClass?: string
}

const Challenge: React.FC<Props> = ({
  num,
  image,
  title,
  description,
  href,
  wrapperClass,
}) => {
  return (
    <div className={`relative ${wrapperClass}`}>
      <span className="absolute top-0 left-[50%] -translate-x-[50%] -translate-y-[50%] bg-primary brightness-110 text-white font-medium font-brandon border-[3px] border-solid border-white text-2xl w-12 h-12 rounded-full flex items-center justify-center z-[1]">
        {num}
      </span>
      <div className="relative">
        <img className="w-full object-cover" src={image} alt="title" />
      </div>
      <h3 className="text-2xl pt-4 mb-1 uppercase">{title}</h3>
      <p className="pb-4">{description}</p>
      {href != null && (
      <Link to={href} className="btn btn-primary">
        Learn More
      </Link>
      )}
    </div>
  )
}

export default Challenge
