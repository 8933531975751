import React from "react"
import { intervalToDuration } from "date-fns"

type Props = {
}

const GO_LIVE = new Date("2023-04-19 08:00")

const Countdown: React.FC<Props> = ({}) => {
  const now = new Date()
  const duration = intervalToDuration({ start: now, end: GO_LIVE })
  return (
    <div className="py-12 lg:py-16 bg-[#f3b247]">
      <div className="container px-2 lg:px-0">
        <h3 className="text-center uppercase text-2xl md:text-3xl lg:text-4xl font-bold">Countdown to the RBAI giving day!</h3>

        <div className="mt-8 grid grid-cols-3 gap-8 mx-auto max-w-xl">
          <div className="flex flex-col items-center">
            <span className="text-5xl font-medium mb-1">{duration.days}</span>
            <span className="font-bold uppercase tracking-wider">Days</span>
          </div>

          <div className="flex flex-col items-center">
            <span className="text-5xl font-medium mb-1">{duration.hours}</span>
            <span className="font-bold uppercase tracking-wider">Hours</span>
          </div>

          <div className="flex flex-col items-center">
            <span className="text-5xl font-medium mb-1">{duration.minutes}</span>
            <span className="font-bold uppercase tracking-wider">Minutes</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Countdown
