import React from "react"
import { Link, PageProps, graphql } from "gatsby"

import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import Challenge from "@/components/Challenge"
import Countdown from "@/components/Countdown"
import { routes } from "@/utils/routes"
import Raised from "@/components/Pledge/Raised"

export const pageQuery = graphql`
  query RBAITogetherQuery {
    sanityPage(title: {eq: "Homepage"}) {
      amountRaised
    }
    allSanityVideo(sort: {fields: position}) {
      edges {
        node {
          title
          position
          videoUrl
          description
          placeholder {
            asset {
              url
            }
          }
        }
      }
    }
  }
`

const RBAITogether: React.FC<PageProps> = ({ data }: { data: any }) => {
  // TODO: console.log({ amountRaised: data.sanityPage.amountRaised })
  function getVideoLink(position: number) {
    if (position === 2) {
      return routes.schoolProjects()
    } else if (position === 3) {
      return routes.socialMobility()
    } else {
      return routes.video({ params: { slug: `video-${position}` } })
    }
  }

  return (
    <Layout
      sectionOne={
        <>
          <video
            id="bgvid"
            className="absolute inset-0 w-full h-full object-cover -z-50 sm:hidden"
            playsInline
            autoPlay
            muted
            loop
          >
            <source src="/videos/rbai_giving_day_mobile.mp4" type="video/mp4" />
          </video>
          <video
            id="bgvid"
            className="absolute inset-0 w-full h-full object-cover -z-50  hidden sm:block"
            playsInline
            autoPlay
            muted
            loop
          >
            <source src="/videos/rbai_giving_day.mp4" type="video/mp4" />
          </video>
          <div className="container text-white pt-60 pb-4 flex flex-col gap-4 items-center text-center">
            <div>
              <div className="pb-2 md:pb-12">
                <h1 className="responsive-heading">
                  <span className="text-primary">RBAI</span> TOGETHER 2023
                </h1>
              </div>
              {/* <Link
                className="font-bold bg-primary brightness-90 hover:brightness-110 self-center mx-auto py-3 px-2"
                to={routes.donate()}
              >
                TOTAL RAISED{" "}
                <b>
                  £{getCMScontent(data, "Amount raised")?.toLocaleString("en")}
                </b>
              </Link> */}
              <div className="pt-8 flex justify-center">
                <img src="/images/down.png" alt="Down arrow" />
              </div>
            </div>
          </div>
        </>
      }
    >
      <SEO title="RBAI Together" />
      {/* <div>
        <div className="container pb-8">
          <div className="flex flex-col lg:flex-row lg:items-end gap-4 lg:gap-28 mt-12 px-4">
            <h2 className="leading-none text-4xl sm:text-7xl">
              OUR
              <br />
              <span className="text-primary">VISION</span>
            </h2>
            <p>
              Built on strong and historic principles, RBAI is constantly
              <br />
              evolving, and the School’s leadership have a clear vision for the
              future.
            </p>
          </div>
          <div className="px-4 mt-24 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <Feature
              title="Maximising"
              description="the academic potential of every pupil."
            />
            <Feature
              title="Promoting"
              description="pupil well-being – intellectually, emotionally, physically and socially."
            />
            <Feature
              title="Supporting"
              description="social mobility by providing the opportunity of a first class education to all."
            />
            <Feature
              title="Equipping"
              description="pupils for future employment opportunities, particularly in science, technology and the creative arts."
            />
            <Feature
              title="Inspiring"
              description="leaders and entrepreneurs, by providing a School environment where pupils can flourish through academic achievement, sport, and the"
            />
          </div>
        </div>
      </div> */}
      {data.sanityPage.amountRaised != null && data.sanityPage.amountRaised > 0 ? (
        <Raised raised={data.sanityPage.amountRaised} />
      ) : (
        <Countdown />
      )}

      <div className="pt-8 lg:pt-16">
        <div className="container px-2 lg:px-0">
          <div>
            <h2 className="leading-none text-3xl sm:text-5xl md:text-6xl">
              19/20 APRIL 2023 - 36 HOURS WHEN WE CAME TOGETHER TO SUPPORT <span className="text-primary">PUPIL BURSARIES</span>{" "} & <span className="text-primary">SCHOOL PROJECTS</span>
            </h2>
          </div>
          <div className="grid md:grid-cols-3 gap-8 mt-16">
            {data.allSanityVideo.edges.map(({ node }) => (
              <>
              {
                node.videoUrl != null ?
                  <Link to={getVideoLink(node.position)}>
                    <Challenge
                      num={node.position}
                      image={node.placeholder.asset.url}
                      title={node.title}
                      description={node.description}
                      href={null}
                      wrapperClass="mb-3"
                    />
                  </Link>
                :
                  <Challenge
                    num={node.position}
                    image={node.placeholder.asset.url}
                    title={node.title}
                    description={node.description}
                    href={null}
                    wrapperClass="mb-3"
                  />
              }
              </>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default RBAITogether
