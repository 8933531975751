import React from "react"

type Props = {
  raised: number | null
}

const Raised: React.FC<Props> = ({ raised }) => {
  const amount = raised || 0
  let graphic = "5k"

  if (amount >= 100000) {
    graphic = "100k"
  } else if (amount >= 95000) {
    graphic = "95k"
  } else if (amount >= 90000) {
    graphic = "90k"
  } else if (amount >= 85000) {
    graphic = "85k"
  } else if (amount >= 80000) {
    graphic = "80k"
  } else if (amount >= 75000) {
    graphic = "75k"
  } else if (amount >= 70000) {
    graphic = "70k"
  } else if (amount >= 65000) {
    graphic = "65k"
  } else if (amount >= 60000) {
    graphic = "60k"
  } else if (amount >= 55000) {
    graphic = "55k"
  } else if (amount >= 50000) {
    graphic = "50k"
  } else if (amount >= 45000) {
    graphic = "45k"
  } else if (amount >= 40000) {
    graphic = "40k"
  } else if (amount >= 35000) {
    graphic = "35k"
  } else if (amount >= 30000) {
    graphic = "30k"
  } else if (amount >= 25000) {
    graphic = "25k"
  } else if (amount >= 20000) {
    graphic = "20k"
  } else if (amount >= 15000) {
    graphic = "15k"
  } else if (amount >= 10000) {
    graphic = "10k"
  } else if (amount >= 5000) {
    graphic = "5k"
  }

  return (
    <div className={`${graphic === "100k" ? "relative" : "py-12"} bg-black`}>
      <div className="container">
        {graphic === "100k" ? (
          <h3 className="uppercase md:absolute z-10 md:left-0 md:right-0 md:top-2 lg:top-6 text-2xl sm:text-3xl lg:text-4xl py-4 lg:pb-6 text-white text-center">
            Total raised £{amount.toLocaleString()}
          </h3>
        ) : (
          <h3 className="uppercase text-2xl sm:text-3xl lg:text-4xl py-4 lg:pb-6 text-white text-center">
            Total raised £{amount.toLocaleString()}
          </h3>
        )}

        <img
          src={`/images/2023/03/thermom/${graphic}.png`}
          className="w-full"
          alt="Amount Raised"
        />
      </div>
    </div>
  )
}

export default Raised
